<template>

<div class="container text-center">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg btn-block" @click="action('1')">1</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg btn-block" @click="action('2')">2</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg btn-block" @click="action('3')">3</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('4')">4</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('5')">5</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('6')">6</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('7')">7</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('8')">8</button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-primary btn-lg" @click="action('9')">9</button>
    </div>
  </div>

  <div>Resultat : {{result}}</div>
</div>

<!--
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
-->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data: () => ({
      result: '',
    }),
  components: {
    //HelloWorld
  },
  methods: {
    action(key) {
        console.log('action', key)
        this.result += key;
/*
        fetch('http://hd1.freebox.fr/pub/remote_control?code=12345678&long=true&key='+key, {method: "GET", headers: {}}).catch(function(err) {
          console.log('error', err);
        });
*/
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
